<template>
  <section id="card-actions">
    <b-row>
      <!-- card collapsible  -->
      <b-col md="12">
        <!-- <invoice-information :data="invoice[0]" :is-loading="isLoading"></invoice-information> -->
      </b-col>

      <!-- card refresh -->
      <b-col
        v-if="!isMobile"
        md="12"
      >
        <invoice-summary
          :data="payments"
          :is-loading="isLoading"
        />
      </b-col>

      <!-- card remove -->
      <b-col
        v-if="!isMobile"
        md="12"
      >
        <last-invoice-summary
          :data="invoice"
          :is-loading="isLoading"
          :default-year="defaultYear"
          :year-list="yearList"
          @searching="searching"
        />
      </b-col>

      <b-col
        v-if="isMobile"
        md="12"
      >
        <invoice-card-list
          :invoice="invoice"
          :payment="payments"
          :is-loading="isLoading"
          :default-year="defaultYear"
          :year-list="yearList"
          @searching="searching"
        />
      </b-col>
    </b-row>

    <b-modal
      id="notification-searching"
      centered
      title="Warning"
      ok-only
      ok-title="Close"
    >
      <b-card-text> Please insert specific date range </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import { isMobile } from 'mobile-device-detect'

import {
  BRow,
  BCard,
  BCol,
  BTable,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BModal,
} from 'bootstrap-vue'

import * as moment from 'moment'
import InvoiceInformation from './InvoiceInformation.vue'
import InvoiceSummary from './InvoiceSummary.vue'
import LastInvoiceSummary from './LastInvoiceSummary.vue'
import InvoiceCardList from './InvoiceCardList.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCard,
    BCol,
    BTable,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    ToastificationContent,
    InvoiceInformation,
    InvoiceSummary,
    LastInvoiceSummary,
    InvoiceCardList,
    BModal,
  },
  data() {
    return {
      defaultYear: new Date().getFullYear(),
      invoice: [],
      isMobile,
      payments: [],
      yearList: [],
      isLoading: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.generateYearList()
      this.isLoading = true
      axios
        .get(`billing/data_invoice/${this.defaultYear}`)
        .then(response => {
          this.invoice = response.data.invoices
          // console.log(this.invoice);
          this.payments = response.data.transactions
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice',
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        })
        .catch(error => {
          if (error.response.data.message == 'Expired token') {
            localStorage.clear()
            this.$router.push({ name: 'login' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Invoice',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Session Expired, Please Login Again',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Invoice',
                icon: 'EditIcon',
                variant: 'success',
                text: error.response.data.message,
              },
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    dateTimeFormat(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    searching(year) {
      if (year === null) {
        // this.$bvModal.show('notification-searching');
      } else {
        this.isLoading = true
        // console.log(this.isLoading);
        axios
          .get(`billing/data_invoice/${year}`)
          .then(response => {
            this.invoice = response.data.invoices
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Invoice Summary',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
          })
          .catch(error => {
            if (error.response.data.message == 'Expired token') {
              localStorage.clear()
              this.$router.push({ name: 'login' })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Invoice',
                  icon: 'EditIcon',
                  variant: 'success',
                  text: 'Session Expired, Please Login Again',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Invoice',
                  icon: 'EditIcon',
                  variant: 'success',
                  text: error.response.data.message,
                },
              })
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    generateYearList() {
      for (let i = this.defaultYear - 1; i <= this.defaultYear + 1; i++) {
        this.yearList.push({ text: i, value: i })
      }
    },
  },
}
</script>
