<template>
  <b-tabs content-class="mt-3" align="center">
    <b-tab title="SUMMARY" active>
      <div v-if="isLoading == true">
        <loading></loading>
      </div>
      <template v-if="isLoading == false">
        <b-card no-body class="card-company-table" v-for="data in payment" :key="data.id">
          <b-card-body>
            <b-row>
              <div class="col-12">
                <h4 class="card-title mb-1" style="margin-bottom:10px; margin-left:-10px;">
                  <span class="badge badge-light-primary"> {{ data.vc_description }} </span><br />
                  <small v-if="data.vc_description === 'Subscription fee'" style="margin-left:5px;">({{ data.vc_service_name }})</small>
                </h4>
              </div>
            </b-row>
            <!--b-row>
              <div class="col-5"><strong>Begin Date </strong></div>
              <div class="col-7">: {{ dateTimeFormat(data.d_oper) }}</div>
            </b-row-->
            <b-row>
              <div class="col-5"><strong>Charge</strong></div>
              <div class="col-7">: IDR {{ formatPrice(data.n_charge_sum) }}</div>
            </b-row>
            <b-row>
              <div class="col-5"><strong>Payment</strong></div>
              <div class="col-7">: IDR {{ formatPrice(data.n_payment_sum) }}</div>
            </b-row>
            <!--b-row>
              <div class="col-5"><strong>Total</strong></div>
              <div class="col-7">: IDR {{ formatPrice(data.n_balance) }}</div>
            </b-row-->
          </b-card-body>
        </b-card>
      </template>
      <template v-if="isLoading == false && payment.length == 0">
        <p style="text-align:center;">You have no Invoice</p>
      </template>
    </b-tab>
    <b-tab title="SUMMARY THIS YEAR">
      <b-form-group label="Select Year">
        <validation-provider #default="{ errors }" name="Year" rules="required">
          <b-form-select v-model="filterDate" :options="yearList" :state="errors.length > 0 ? false : null" @change="find"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <div v-if="isLoading == true">
        <loading></loading>
      </div>
      <template v-if="isLoading == false">
        <b-card no-body class="card-company-table" v-for="data in invoice" :key="data.id">
          <b-card-body>
            <b-row>
              <b-col md="12">
                <h4>
                  <span style="margin-bottom:10px; margin-left:-5px;" class="badge badge-light-primary"> {{ data.bill_period }} </span>
                  <span class="card-title mb-1" style="float:right;">
                    <b-badge pill variant="light-success" v-if="data.status_payment == '2'">Paid</b-badge>
                    <b-badge pill variant="light-danger" v-else> Unpaid </b-badge>
                  </span>
                </h4>
                <div style="clear:both;"></div>
                <b-row>
                  <div class="col-5"><strong>No Invoice</strong></div>
                  <div class="col-7">: {{ data.invoice_no }}</div>
                </b-row>
                <b-row>
                  <div class="col-5"><strong>Total</strong></div>
                  <div class="col-7">: IDR {{ formatPrice(data.total_amount_due) }}</div>
                </b-row>
                <b-row>
                  <div class="col-5"><strong>Due Date</strong></div>
                  <div class="col-7">: {{ data.due_date }}</div>
                </b-row>
                <br/>
                <span style="float: left" class="card-text"></span>
                <span style="float: right" class="card-text" @click="download(data.invoice_no)">
                  <feather-icon size="20" :icon="'DownloadIcon'" />
                </span>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </template>
      <template v-if="isLoading == false && invoice.length == 0">
        <p style="text-align:center;">You have no Invoice</p>
      </template>
    </b-tab>
  </b-tabs>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Loading from "@core/components/loading/Loading.vue";
import * as moment from "moment";
import Ripple from "vue-ripple-directive";
import axios from "axios";

import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCard,
  BFormDatepicker,
  BFormGroup,
  BButton,
  BTabs,
  BTab,
  BBadge,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BFormSelect,
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Loading,
    BCard,
    BFormDatepicker,
    BFormGroup,
    Ripple,
    BButton,
    BTabs,
    BTab,
    BBadge,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  computed: {
    nowLoading() {
      return this.loading === null ? this.isLoading : this.loading;
    },
  },
  data() {
    return {
      loading: false,
      filterDate: this.defaultYear,
    };
  },
  props: {
    invoice: {
      type: Array,
      default: () => [],
    },
    payment: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    defaultYear: {
      type: Number,
      default: 2021,
    },
    yearList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    removeMinus(value) {
      return value.replace("-", "");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateTimeFormat: function (date) {
      return moment(date).format("Do MMMM YYYY");
    },
    find: function () {
      this.isLoading = true;
      this.$emit("searching", this.filterDate);
    },
    download: function (invoice) {
      //window.open();
      const clean_invoice = invoice.split("/");
      this.loading = true;
      
      // --TESTING DOANG. SILAKAN DI HAPUS--
      //clean_invoice[1] = '20210032058';
      // --------------------------
      axios({
        url: "https://api-portal.netciti.co.id/api-invoice/pdf.php?invoice=" + clean_invoice[1],
        method: "GET",
        //headers: { "Access-Control-Allow-Origin": "*" },
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", invoice + ".pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          // if (error.response.status === 401) {
          //   this.expired(error, "");
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
