<template>
  <b-card-actions
    v-if="data"
    :title="$t('form.page.invoice.Invoice Summary')"
    action-collapse
  >
    <b-card-body>
      <div v-if="isLoading">
        <b-row>
          <b-col md="12"> <loading></loading> </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col>
            <b-table :items="data" responsive :fields="fields" class="mb-0">
              <template #cell(d_oper)="data">
                <template v-if="data.value !== ''">{{
                  dateTimeFormat(data.value)
                }}</template>
              </template>
              <template #cell(d_end)="data">
                <template v-if="data.value !== ''">{{
                  dateTimeFormat(data.value)
                }}</template>
              </template>
              <template #cell(d_oper_real)="data">
                <template v-if="data.value !== ''"
                  >{{ dateTimeFormat(data.value) }}<br
                /></template>
              </template>
              <template #cell(n_charge_sum)="data">
                {{ formatPrice(data.value) }}
              </template>
              <template #cell(n_balance)="data">
                {{ formatPrice(data.value) }}
              </template>
              <template #cell(n_payment_sum)="data">
                {{ formatPrice(data.value) }}
              </template>
              <template #cell(vc_service_name)="data">
                {{ data.item.vc_description }}<br />
                <small v-if="data.item.vc_description === 'Subscription fee'"
                  >({{ data.value }})</small
                >
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- </b-card>  -->
      </div>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Loading from "@core/components/loading/Loading.vue";
import * as moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { isMobile } from "mobile-device-detect";

import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCard,
  BFormDatepicker,
  BFormGroup,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Loading,
    BCard,
    BFormDatepicker,
    flatPickr,
    BFormGroup,
    Ripple,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // fields: [
      //   { key: "vc_doc_no", label: "RECEPIENT NO" },
      //   { key: "n_sum_wo_tax", label: "BEFORE TAX" },
      //   { key: "n_sum_tax", label: "TAX" },
      //   { key: "n_sum", label: "TOTAL" },
      //   { key: "action", label: "ACTION" },
      // ],
      filterDate: null,
      isMobile: isMobile,
      fields: [
        { key: "d_oper", label: "BEGIN DATE" },
        { key: "d_oper_real", label: "PAYMENT DATE" },
        { key: "vc_service_name", label: "SERVICE" },
        { key: "n_charge_sum", label: "CHARGE" },
        { key: "n_payment_sum", label: "PAYMENT" },
        { key: "n_balance", label: "TOTAL" },
        { key: "vc_description", label: "REMARK" },
        // { key: "vc_author_code", label: "AUTHOR" },
      ],
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },

  methods: {
    removeMinus(value) {
      return value.replace("-", "");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateTimeFormat: function (date) {
      return moment(date).format("Do MMMM YYYY HH:mm:ss");
    },
    find: function () {
      this.$emit("searching", this.filterDate);
      //console.log(this.filterDate);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
