<template>
  <b-card-actions
    v-if="data"
    :title="$t('form.page.invoice.Invoice Summary This Year')"
    action-collapse
  >
    <b-card-body>
      <div v-if="nowLoading">
        <loading></loading>
      </div>
      <div v-else>
        <b-row>
          <b-col lg="3">
            <b-form-group label="Select Year">
              <validation-provider #default="{ errors }" name="Year" rules="required">
                <b-form-select
                  v-model="filterDate"
                  :options="yearList"
                  :state="errors.length > 0 ? false : null"
                  @change="find"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table show-empty responsive :items="data" :fields="fields" bordered>
          <template #cell(total_amount_due)="data">
            IDR {{ formatPrice(data.value) }}
          </template>
          <template #cell(payment)="data">
            <template v-if="data.item.status_payment == '2'">
              <b-badge pill variant="light-success"> Paid </b-badge>
            </template>
            <template v-else>
              <b-badge pill variant="light-danger"> Unpaid </b-badge>
            </template>
          </template>
          <template #empty style="text-align: center">
            <span>You have no Invoice</span>
          </template>
          <template #cell(action)="data">
            <div>
              <b-link
                class="nav-link"
                title="Download Invoice"
                @click="download(data.item.invoice_no)"
              >
                <feather-icon icon="FileTextIcon" size="21" /> Invoice
              </b-link>
              &nbsp;
              <b-link v-if="data.item.file_faktur != null"
                class="nav-link"
                title="Download Faktur Pajak"
                @click="download_tax(data.item.file_faktur)"
              >
                <feather-icon icon="FileIcon" size="21" /> Faktur Pajak
              </b-link>
            </div>
          </template>
        </b-table>
      </div>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Loading from "@core/components/loading/Loading.vue";
import axios from "axios";
import axios2 from "@axios";

import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { isMobile } from "mobile-device-detect";

import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
  BBadge,
  BFormGroup,
  BButton,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    flatPickr,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Loading,
    BLink,
    BBadge,
    BFormGroup,
    BButton,
    BFormSelect,
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    defaultYear: {
      type: Number,
      default: 2021,
    },
    yearList: {
      type: Array,
      default: () => [],
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filterDate: this.defaultYear,
      loading: null,
      isMobile: isMobile,
      fields: [
        { key: "bill_period", label: "BILL PERIOD" },
        { key: "due_date", label: "DUE DATE" },
        { key: "invoice_no", label: "NO INVOICE" },
        { key: "total_amount_due", label: "TOTAL CHARGE" },
        { key: "payment", label: "PAYMENT" },
        { key: "Action", label: "DOWNLOAD" },
        // { key: "Tax", label: "TAX" },
      ],
    };
  },
  computed: {
    nowLoading() {
      return this.loading === null ? this.isLoading : this.loading;
    },
  },
  methods: {
    removeMinus(value) {
      return value.replace("-", "");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    download: function (invoice) {
      const clean_invoice = invoice.split("/");
      this.loading = true;

      // --TESTING DOANG. SILAKAN DI HAPUS--
      //clean_invoice[1] = '20210032058';
      // --------------------------
      axios({
        url:
          "https://api-portal.netciti.co.id/api-invoice/pdf.php?invoice=" +
          clean_invoice[1],
        method: "GET",
        //headers: { "Access-Control-Allow-Origin": "*" },
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", invoice + ".pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          // if (error.response.status === 401) {
          //   this.expired(error, "");
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    download_tax: function (file_faktur) {
      this.loading = true;
      axios2({
        url: "export/faktur/"+file_faktur,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file_faktur);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          // if (error.response.status === 401) {
          //   this.expired(error, "");
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    find: function () {
      this.$emit("searching", this.filterDate);
      //console.log(this.filterDate);
    },
  },
};
</script>
